<template>

<div>

	<com-title text="Attendees" />

	<com-item route="Convention.Badges.Holders" text="Badges" :count="$convention.count.badges.holders" />
	<com-item route="Convention.Badges.Checkins" text="Checkins" :count="$convention.count.badges.checkins" />
	<com-item route="Convention.Badges.Transfers" text="Transfers" :count="$convention.count.badges.transfers" />

	<com-title text="Convention" />

	<com-item route="Convention.Badges.Groups" text="Groups" :count="$convention.count.badges.groups" />
	<com-item route="Convention.Badges.Types" text="Types" :count="$convention.count.badges.types" />
	<com-item route="Convention.Badges.Settings" text="Settings" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../..//panel/common/Title'),
		'com-item': () => import('./../..//panel/common/Item')
	}

}

</script>

<style scoped>

</style>
